
const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
    ////////////////////////////////////Video////////////////////////////////////////

    ListVideo: (_, configuration = {}) => axios.get(`/video`, configuration),
    VideoId: (id, query = {}, configuration = {}) => axios.get(`/video/${id}${mapToQueryString(query)}`, configuration),

    CreateVideo: (body, configuration = {}) => axios.post('/video', withoutInvalidValues(body), configuration),

    EditVideo: ( body, configuration = {}) => axios.put(`/video`, withoutInvalidValues(body), configuration),

    DeleteVideo: (id, body, configuration = {}) => axios.delete(`/video/${id}`, withoutInvalidValues(body), configuration),

    ////////////////////////////////////PostCard////////////////////////////////////////

    ListPost: (_, configuration = {}) => axios.get(`/postcard`, configuration),

    PostId: (id, query = {}, configuration = {}) => axios.get(`/postcard/${id}${mapToQueryString(query)}`, configuration),

    CreatePost: (body, configuration = {}) => axios.post('/postcard', withoutInvalidValues(body), configuration),

    EditPost: ( body, configuration = {}) => axios.put(`/postcard`, withoutInvalidValues(body), configuration),

    DeletePost: (id, body, configuration = {}) => axios.delete(`/postcard/${id}`, withoutInvalidValues(body), configuration),




  
  })
  
  export default endpoints