const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
    list: (query = {}, configuration = {}) => axios.get(`/user${mapToQueryString(query)}`, configuration),
    income: (query = {}, configuration = {}) => axios.get(`/income${mapToQueryString(query)}`, configuration),
    below: (id ,query = {}, configuration = {}) => axios.get(`/user/below/${id}${mapToQueryString(query)}`, configuration),
    donations: (id ,query = {}, configuration = {}) => axios.get(`/user/donations/${id}${mapToQueryString(query)}`, configuration),
    show: (id, query = {}, configuration = {}) => axios.get(`/user/${id}${mapToQueryString(query)}`, configuration),
    UserInfo: (configuration = {}) => axios.get(`/user/info/`, configuration),
    FinByld: (id, query = {}, configuration = {}) => axios.get(`/user/${id}${mapToQueryString(query)}`, configuration),
    userList: ( query = {}, configuration = {}) => axios.get(`/user${mapToQueryString(query)}`, configuration),
    ListExperto: ( query = {}, configuration = {}) => axios.get(`/expert${mapToQueryString(query)}`, configuration),
    AdminUserList: ( query = {}, configuration = {}) => axios.get(`/user/admin${mapToQueryString(query)}`, configuration),
    NotificationsList: ( query = {}, configuration = {}) => axios.get(`/notifications${mapToQueryString(query)}`, configuration),
    getProjects: ( query = {}, configuration = {}) => axios.get(`/project${mapToQueryString(query)}`, configuration),
    
    create: (body, configuration = {}) => axios.post('/user', withoutInvalidValues(body), configuration),
    createUsuarioAdmin: (body, configuration = {}) => axios.post('/user/admin', withoutInvalidValues(body), configuration),
    phoneVerify: (body, configuration = {}) => axios.post('/user/phoneVerify', withoutInvalidValues(body), configuration),
    createExpert: (body, configuration = {}) => axios.post('/expert', withoutInvalidValues(body), configuration),
    createNotification: (body, configuration = {}) => axios.post('/notifications', withoutInvalidValues(body), configuration),

    delete: (id, body, configuration = {}) => axios.delete(`/User/${id}`, withoutInvalidValues(body), configuration),
    disabledExpert: (id, configuration = {}) => axios.delete(`/expert/${id}`, configuration),
    deleteAdmin: (id, body, configuration = {}) => axios.delete(`/admin/${id}`, withoutInvalidValues(body), configuration),
    deleteNotification: (id, body, configuration = {}) => axios.delete(`/notifications/${id}`, withoutInvalidValues(body), configuration),

    updateAdminUser: (body, configuration = {}) => axios.put(`/user/admin/`, withoutInvalidValues(body), configuration),
    updateUser: ( body, configuration = {}) => axios.put(`/user`, withoutInvalidValues(body), configuration),
    updateExpert: ( body, configuration = {}) => axios.put(`/expert`, withoutInvalidValues(body), configuration),
    updateNotification: (body, configuration = {}) => axios.put(`/notifications`, withoutInvalidValues(body), configuration),
    updateLanguage: (id, body, configuration = {}) => axios.put(`/user/language/${id}`, withoutInvalidValues(body), configuration),

  })
  
  export default endpoints