const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
  list: (query = {}, configuration = {}) => axios.get(`/${mapToQueryString(query)}`, configuration),
  show: (id, query = {}, configuration = {}) => axios.get(`//${id}${mapToQueryString(query)}`, configuration),
  PublicationsList: ( query = {}, configuration = {}) => axios.get(`/${mapToQueryString(query)}`, configuration),
  
  create: (body, configuration = {}) => axios.post('/', withoutInvalidValues(body), configuration),

  delete: (id, configuration = {}) => axios.delete(`//${id}`, configuration),

  update: (body, configuration = {}) => axios.put(`/`, withoutInvalidValues(body), configuration),
})

export default endpoints