import axios from "axios";
import { handleError } from "../helpers/handleError";

const onRequest = (config) => {
  const token = localStorage.getItem("@TOKEN");

  if (token) {
    config.headers["Authorization"] = token;
  }

  return config;
};

/**
 *
 * @param {AxiosResponse} response
 * @returns {AxiosResponse}
 */
const onResponse = (response) => response;

/**
 *
 * @param {Error} error
 * @returns {Promise<Error>}
 */
const onRequestError = (error) => Promise.reject(error);

/**
 *
 * @param {Error} error
 * @returns {Promise<Error>}
 */
const onResponseError = (error) => {
  try {
    if (error.response.status === 401) {
      handleError(error.response.data.message_en);
    } else if (error.response.status === 403) {
      localStorage.removeItem("@TOKEN");
      localStorage.removeItem("@USER");
      handleError(error.response.data.message_en);
    } else if (error.response.status === 500) {
      handleError(error.response.data.message_en);
    }
    Promise.reject(error);
  } catch (error) {
    Promise.reject(error);
  }
};

const useConfiguredAxios = () => {
  const _axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  _axios.interceptors.request.use(onRequest, onRequestError);
  _axios.interceptors.response.use(onResponse, onResponseError);

  return _axios;
};

export default useConfiguredAxios;
