const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
    getProjects: ( query = {}, configuration = {}) => axios.get(`/project${mapToQueryString(query)}`, configuration),

    createProject: (body, configuration = {}) => axios.post('/project', withoutInvalidValues(body), configuration),

    updateProject: (body, configuration = {}) => axios.put(`/project`, withoutInvalidValues(body), configuration),

    deleteProject: (id, body, configuration = {}) => axios.delete(`/project/${id}`, withoutInvalidValues(body), configuration),




    // getProjectsid: (id ,query = {}, configuration = {}) => axios.get(`/project/${id}${mapToQueryString(query)}`, configuration),


  })
  
  export default endpoints